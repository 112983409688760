@import url(https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 40px;
  font-family: "Bungee Shade";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0; 
  padding: 0; 
}
body {
  font-size: 62.5%; 
  line-height: 1.4; 
  font-family: Arial, Helvetica, sans-serif;
}

a {
  color: #333; 
  text-decoration: none;
}

.container {
  margin: 4% 2%;
  padding: 4%;

  border: 1px solid rgb(210, 210, 210);
  border-radius: 6px;
  box-shadow: 0px 1px 6px -2px rgb(128, 127, 127);

  background-color: white;
}
.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6e7f7;
}
.nav-bar h1 {
  padding: 2%;
}
.nav-bar > ul {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 6%;
  padding: 2%;
}

.nav-bar li {
  list-style: none;
  margin-right: 6px;
  color: #204963;
}

.nav-bar a {
  text-decoration: none;
  padding: 6px;
  color: #204963;
}

