* {
  box-sizing: border-box;
  margin: 0; 
  padding: 0; 
}
body {
  font-size: 62.5%; 
  line-height: 1.4; 
  font-family: Arial, Helvetica, sans-serif;
}

a {
  color: #333; 
  text-decoration: none;
}

.container {
  margin: 4% 2%;
  padding: 4%;

  border: 1px solid rgb(210, 210, 210);
  border-radius: 6px;
  box-shadow: 0px 1px 6px -2px rgb(128, 127, 127);

  background-color: white;
}